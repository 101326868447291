/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles, useTheme } from "react-jss";
import Layout from '../components/Layout';
import Empty from '../components/Empty';
import Button from '../components/Button';
import { clear, get, update } from '../api/Cart';
import { getOne } from '../api/Product';
import { ReactComponent as HeightIcon } from '../assets/height.svg';
import { ReactComponent as LengthIcon } from '../assets/length.svg';
import { ReactComponent as DiameterIcon } from '../assets/diameter.svg';
import { ReactComponent as WidthIcon } from '../assets/width.svg';
import ProductCounter from '../components/ProductCount';
import Loader from '../components/Loader';
import clsx from 'clsx';
import { create } from '../api/Sale';
import { useHistory } from 'react-router';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    height: '100%',

    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      minHeight: '500px',
      height: '70vh',
    },

    '@media screen and (max-width: 1200px)': {
      flexDirection: 'column',
      height: 'auto',
    }
  },
  products: {
    flex: '1 1 70%',
    height: '100%',
    paddingRight: '20px',
    display: 'flex',
    flexDirection: 'column',
    '@media screen and (max-width: 1400px)': {
      paddingRight: '7px',
      flex: '1 1 60%',
    },
    '@media screen and (max-width: 1200px)': {
      height: 'auto',
    },
    '@media screen and (max-width: 900px)': {
      paddingRight: '0',
    },
  },
  productsInner: {
    maxHeight: '100%',
    flex: '1 1 auto',
    overflowY: 'auto',
  },
  productsSum: {
    height: '60px',
    minHeight: '60px',
    maxHeight: '60px',
    paddingTop: '20px',
    paddingRight: '65px',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',

    '@media screen and (max-width: 900px)': {
      paddingTop: '20px',
      paddingRight: '0px',
    },

    '@media screen and (max-width: 1200px)': {
      flexDirection: 'column',
      height: '120px',
      minHeight: '120px',
      maxHeight: '120px',
      justifyContent: 'flex-start',
      alignItems: 'flex-end'
    },
  },
  productsSumInner: {
    fontFamily: ({theme}) => theme.fontFamilyBold,
    color: ({theme}) => theme.mainColor,
    borderTop: ({theme}) => `2px solid ${theme.grayColor}`,
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '@media screen and (max-width: 1200px)': {
      marginBottom: '10px'
    },
  },
  productsSumOnSale: {
    textDecoration: 'line-through',
  },
  productSaleSection: {
    padding: '10px 65px 10px 40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    zIndex: 2,
    color: 'white',
    position: 'absolute',
    width: '490px',
    top: '100%',
    right: '0',

    '@media screen and (max-width: 900px)': {
      marginLeft: '0',
      marginRight: '0',
      alignItems: 'flex-start',
      padding: '10px 0 10px 20px',
      width: '100%',
      maxWidth: '490px',
    },

    '@media screen and (max-width: 1200px)': {
      position: 'relative',
      top: 0,
    },

    '&::after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '100%',
      background: '#202020',
      zIndex: 1,
      borderRadius: '20px',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      height: '100%',
    }
  },
  productSaleSectionLeftBlock: {
    zIndex: 2,
    fontFamily: ({theme}) => theme.fontFamilyBold,
  },
  productSaleSectionRightBlock: {
    zIndex: 2,
    fontFamily: ({theme}) => theme.fontFamilyMedium,
    fontSize: '22px',

    '@media screen and (max-width: 900px)': {
      fontSize: '16px',
    },
  },
  product: {
    display: 'flex',
    height: '250px',
    width: '100%',
    alignItems: 'center',
    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      height: '150px',
    },
    '@media screen and (max-width: 900px)': {
      height: 'auto',
    },
  },
  productImage: {
    height: '250px',
    width: '160px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      height: '150px',
    },

    '& img': {
      maxHeight: '100%',
      maxWidth: '100%',
      height: 'auto',
      width: 'auto',
    },

    '@media screen and (max-width: 1200px)': {
      height: '150px',
      width: '160px',
    },

    '@media screen and (max-width: 900px)': {
      width: '100px',
    },
  },
  productInfo: {
    borderRadius: '10px',
    marginLeft: '-50px',
    padding: '18px 65px',
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',

    '@media screen and (max-width: 900px)': {
      padding: '18px 10px',
      alignItems: 'flex-start',
      marginLeft: '0px',
      flexDirection: 'column',
    },

    '&:hover': {
      backgroundColor: ({theme}) => theme.whiteColor,
      boxShadow: '2px 4px 15px rgba(0, 0, 0, 0.05)',

      '& .removeProduct': {
        display: 'block'
      },
    },

    '& .removeProduct': {
      backgroundColor: ({theme}) => theme.mainColor,
      color: ({theme}) => theme.whiteColor,
      fontSize: '20px',
      transform: 'rotate(-45deg)',
      position: 'absolute',
      right: '10px',
      top: '10px',
      width: '21px',
      cursor: 'pointer',
      lineHeight: 1.1,
      height: '21px',
      borderRadius: '50%',
      display: 'none',
      alignItems: 'center',
      textAlign: 'center',
      '@media screen and (max-width: 900px)': {
        display: 'block'
      }
    }
  },
  productCol: {
    width: '25%',

    '@media screen and (max-width: 900px)': {
      width: '100%',
    },

    '& + &': {
      marginLeft: '20px',

      '@media screen and (max-width: 900px)': {
        marginLeft: '0',
        marginTop: '5px',
      }
    }
  },
  productColFixedWidth: {
    width: '70px',
    display: 'flex',
    justifyContent: 'flex-end',

    '@media screen and (max-width: 900px)': {
      fontFamily: ({theme}) => theme.fontFamilyBold,
      width: '100%',
    },
  },
  productName: {
    fontFamily: ({theme}) => theme.fontFamilyBold,
    color: ({theme}) => theme.mainColor,
  },
  productArticle: {
    color: ({theme}) => theme.lightGrayColor,
  },
  productInfoWithIcon: {
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '@media screen and (max-width: 900px)': {
      width: 'auto',
      alignSelf: 'flex-start',
      justifyContent: 'flex-start',
    },

    '& span': {
      '@media screen and (max-width: 900px)': {
        marginLeft: '10px'
      },
    },

    '& + &': {
      marginTop: '15px',
    }
  },
  productCount: {
    '@media screen and (max-width: 900px)': {
      width: 'auto',
      alignSelf: 'flex-end',
      marginRight: '-10px',
    },
  },
  order: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: '1 1 30%',
    paddingLeft: '30px',
    minWidth: '330px',

    '@media screen and (max-width: 1400px)': {
      paddingLeft: '15px',
      minWidth: '250px',
    },

    '@media screen and (max-width: 1200px)': {
      paddingLeft: '0px',
      minWidth: '300px',
    }
  },
  orderFormGroup: {
    '& + &': {
      marginTop: '60px'
    }
  },
  orderRadioTip: {
    color: ({theme}) => theme.darkerGrayColor,
    paddingLeft: '25px',
    paddingTop: '3px',
  },
  orderLabel: {
    fontFamily: ({theme}) => theme.fontFamilyBold
  },
  orderInput: {
    fontFamily: ({theme}) => theme.fontFamilyDefault,
    border: 'none',
    outline: 'none',
    borderBottom: ({theme}) => `1px solid ${theme.darkerGrayColor}`,
    width: '100%',
    marginTop: '10px',
    height: '40px',
  },
  orderRadio: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    cursor: 'pointer',

    '& input': {
      cursor: 'pointer',
      margin: '0 10px 0 0',
    },

    '& label': {
      cursor: 'pointer',
    },

    "& input[type='radio']:after": {
      width: '15px',
      height: '15px',
      borderRadius: '15px',
      top: '-2px',
      left: '-1px',
      position: 'relative',
      content: "''",
      display: 'inline-block',
      visibility: 'visible',
      backgroundColor: 'white',
      border: ({theme}) => `1px solid ${theme.lightGrayColor}`,
  },

  "& input[type='radio']:checked:after": {
      width: '15px',
      height: '15px',
      borderRadius: '15px',
      top: '-2px',
      left: '-1px',
      position: 'relative',
      outline: ({theme}) => `3px solid ${theme.mainColor}4D`,
      border: ({theme}) => `1px solid ${theme.mainColor}`,
      background: ({theme}) => `radial-gradient(${theme.mainColor} 0%,${theme.mainColor} 50%,#E8E3F8 51%)`,
      content: "''",
      display: 'inline-block',
      visibility: 'visible',
    }
  },
  orderButtons: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',

    '@media screen and (max-width: 1200px)': {
      justifyContent: 'flex-end',
    },

    '@media screen and (max-width: 900px)': {
      marginTop: '20px',
    },

    '& div': {
      cursor: 'pointer',
      marginRight: '20px',
    },

    '&.-disabled button': {
      opacity: '.7',
      cursor: 'unset',
    }
  },
});

const CartPage = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [loading, setLoading] = useState(false)
  const [cart, setCart] = useState([])
  const [orderStep, setOrderStep] = useState(1)
  const [formValues, setFormValues] = useState({delivery: "np", payment: "card"})
  const history = useHistory()
  const accumulatedPrice = useMemo(() =>
    cart.length
      ? cart
          .map((el) => Math.ceil(el.product.product_modifications.filter((e) => e.id == el.current)[0].price*el.count))
          .reduce((accumulator, curr) => accumulator + curr)
      : 0
  , [cart])

  const salePercentage = useMemo(() =>
    accumulatedPrice >= 16000
      ? accumulatedPrice >= 26000
        ? 15
        : 10
      : 5
  , [accumulatedPrice])

  const hasSale = useMemo(() =>
    false
  , [cart])

  const theSum = useMemo(() =>
    hasSale && accumulatedPrice
      ? Math.ceil(accumulatedPrice*(1 - salePercentage/100))
      : accumulatedPrice
  , [accumulatedPrice])


  useEffect(() => {
    document.title = 'Корзина - Christmas products'
  }, [])

  useEffect(() => {
    const currentCart = get();

    if(Object.keys(currentCart).length) {
      setLoading(true)
      Promise.all(Object.keys(currentCart).map((k) => getOne(k.split(':')[0])))
        .then((res) => {
          Object.keys(currentCart).map((k, i) => {
            res[i].current = k.split(':')[1]
            res[i].count = currentCart[k]
          })
          setCart(res)
        })
        .finally(() => setLoading(false))
    }
  }, [sessionStorage])

  const setProductCount = (cartItem) => (value) => {
    let newCart = [...cart]
    newCart.filter((el) => el.current == cartItem.current)[0].count = value
    update(`${cartItem.product.id}:${cartItem.current}`, value)
    setCart(newCart)
  }

  const inputOnChange = (inputName) => (e) => {
    setFormValues({...formValues, [inputName]: e.target.value })
  }

  const submitOnClick = (e) => {
    if(!formValues['phone']) {
      return;
    }
    if(orderStep !== 2) {
      setOrderStep(orderStep + 1)
    } else {
      const saleSlipsData = cart.map((el) => {
        return {
          count: el.count,
          product_modification_id: el.current,
        }
      })
      const note = `
        deliver_to: ${formValues['city']} || ${formValues['region']} || ${formValues['department']},
        \nphone: ${formValues['phone']},
        \nrecipient: ${formValues['name']},
        \nrecipient_surname: ${formValues['surname']},
        \npayment_type: ${formValues['payment']},
        \ndelivery_type: ${formValues['delivery']},
      `
      setLoading(true)
      create(note, saleSlipsData)
        .then((res) => {
          setLoading(false);
          dataLayer?.push(['event', 'conversion', {
            'send_to': 'AW-938400942/Rf1HCLuYxIAYEK65u78D',
            'value': theSum,
            'currency': 'UAH',
            'transaction_id': `conversion-${res.id}`
          }])
          clear();
          history.push('/cart-submitted')
      })
    }
  }

  const removeItem = (cartItem) => {
    let newCart = [...cart].filter((el) => el.current !== cartItem.current)
    update(undefined, undefined, `${cartItem.product.id}:${cartItem.current}`)
    setCart(newCart)
  }

  if(loading)
    return (
      <Layout>
        <Loader />
      </Layout>
    )

  if(!loading && !cart.length)
    return (
      <Layout>
        <Empty>Тут поки пусто :)</Empty>
      </Layout>
    )

  return (
    <Layout>
      <div className={classes.root}>
        <div className={classes.products}>
          <div className={classes.productsInner}>
            {cart.map((el) =>
              <div className={classes.product} key={el.product.current}>
                <div className={classes.productImage}>
                  <img src={el.product.image_url} />
                </div>
                <div className={classes.productInfo}>
                  <div className={classes.productCol}>
                    <div className={classes.productName}>
                      {el.product.name}
                    </div>
                    <div className={classes.productArticle}>
                      {el.product.secondary_id}
                    </div>
                  </div>
                  <div className={classes.productCol}>
                    {['ялинка', 'гірлянда'].includes(el.product.product_type) &&
                      <div className={classes.productInfoWithIcon}>
                        {el.product.product_type === 'ялинка' ? <HeightIcon /> : <LengthIcon />}
                        <span>{el.product.product_modifications.filter((e) => e.id == el.current)[0].height}см</span>
                      </div>
                    }
                    {['ялинка', 'вінок'].includes(el.product.product_type) &&
                      <div className={classes.productInfoWithIcon}>
                        {el.product.product_type === 'вінок' ? <DiameterIcon /> : <WidthIcon />}
                        <span>{el.product.product_modifications.filter((e) => e.id == el.current)[0].diameter}см</span>
                      </div>
                    }
                    </div>
                  <div className={clsx(classes.productCol, classes.productCount)}>
                    <ProductCounter productCount={el.count} setProductCount={setProductCount(el)}/>
                  </div>
                  <div className={clsx(classes.productCol, classes.productColFixedWidth)}>
                    {Math.ceil(el.product.product_modifications.filter((e) => e.id == el.current)[0].price*el.count)} ₴
                  </div>
                  <div className="removeProduct" onClick={() => removeItem(el)}>+</div>
                </div>
              </div>
            )}
          </div>
          <div className={classes.productsSum}>
            <div className={clsx(classes.productsSumInner, hasSale && classes.productsSumOnSale)}>
              {accumulatedPrice} ₴
            </div>
            {hasSale &&
              <div className={classes.productSaleSection}>
                <div className={classes.productSaleSectionLeftBlock}>
                  ціна зі знижкою
                </div>
                <div className={classes.productSaleSectionRightBlock}>
                  {theSum} ₴
                </div>
              </div>
            }
          </div>
        </div>
        <div className={classes.order}>
          {orderStep === 1
            ? <>
                <div className={classes.orderFormGroup}>
                  <div className={classes.orderLabel}>
                    Заповніть дані
                  </div>
                  <input value={formValues['name'] || ''} onChange={inputOnChange('name')} className={classes.orderInput} type="text" name="name" placeholder="ім'я та побатькові"/>
                  <input value={formValues['surname'] || ''} onChange={inputOnChange('surname')} className={classes.orderInput} type="text" name="surname" placeholder="прізвище"/>
                  <input value={formValues['phone'] || ''} onChange={inputOnChange('phone')} className={classes.orderInput} type="text" name="phone" placeholder="телефон"/>
                </div>
                <div className={classes.orderFormGroup}>
                  <div className={classes.orderLabel}>
                    Спосіб доставки
                  </div>
                  <div className={classes.orderRadio}>
                    <input type="radio" id="np" name="delivery" value="np" checked={formValues['delivery'] == 'np'} onChange={inputOnChange('delivery')} />
                    <label for="np">доставка Новою Поштою у відділення</label>
                  </div>
                  <div className={classes.orderRadio}>
                    <input type="radio" id="currier" name="delivery" value="currier" checked={formValues['delivery'] == 'currier'} onChange={inputOnChange('delivery')} />
                    <label for="currier">кур’єрська доставка Новою Поштою</label>
                  </div>
                </div>
              </>
            : <>
                <div className={classes.orderFormGroup}>
                  <div className={classes.orderLabel}>
                    Адреса доставки
                  </div>
                  <input value={formValues['city'] || ''} onChange={inputOnChange('city')} className={classes.orderInput} type="text" name="city" placeholder="місто"/>
                  <input value={formValues['region'] || ''} onChange={inputOnChange('region')} className={classes.orderInput} type="text" name="region" placeholder="область"/>
                  <input value={formValues['department'] || ''} onChange={inputOnChange('department')} className={classes.orderInput} type="text" name="department" placeholder="відділення чи адреса"/>
                </div>
                <div className={classes.orderFormGroup}>
                  <div className={classes.orderLabel}>
                    Спосіб оплати
                  </div>
                  <div className={classes.orderRadio}>
                    <input type="radio" id="card" name="payment" value="card" checked={formValues['payment'] == 'card'} onChange={inputOnChange('payment')} />
                    <label for="card">оплата на карту ПриватБанку</label>
                  </div>
                  <div className={classes.orderRadio}>
                    <input type="radio" id="cash" name="payment" value="cash" checked={formValues['payment'] == 'cash'} onChange={inputOnChange('payment')}/>
                    <label for="cash">накладений платіж на Новій пошті</label>
                  </div>
                  <div className={classes.orderRadioTip}>
                    *за 20% передоплатою
                  </div>
                </div>
              </>
          }
          <div className={clsx(classes.orderButtons, !formValues['phone'] && '-disabled')}>
            {orderStep > 1 ? <div onClick={(e) => setOrderStep(orderStep-1)}>назад</div> : null}
            <Button onClick={submitOnClick}>далі</Button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CartPage;
