import { apiCallWrapper } from './apiCallWrapper';

export const create = (note, order_items_attributes) => {
  return apiCallWrapper(`/orders`, {
    method: 'POST',
    body: JSON.stringify({
      order: {
        additional_fields: { note },
        order_items_attributes
      }
    })
  })
}
